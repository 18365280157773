//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CajaServices from "../../../Services/CajaServices";

const cajaServices = new CajaServices();
export default {
  name: "buscarVentasModal",
  props: ["model", "caja"],
  data() {
    return {
      filtros: {
        busqueda: "",
        tipo: 100,
        orden: 6,
        cajaId: JSON.parse(localStorage.getItem("cajaObj")).caja_id
      },
      ventas: [],
      tipoOpciones: [
        {label: "Contado", value: 100},
        {label: "Crédito", value: 101},
      ],
      ordenOpciones: [
        {label: "Folio Ascendente", value: 1},
        {label: "Folio Descendente", value: 2},
        {label: "Cliente A-Z", value: 3},
        {label: "Cliente Z-A", value: 4},
        {label: "Fecha ascendente", value: 5},
        {label: "Fecha descendente", value: 6},
      ],
      columnas: [
        {label: 'folio', align: 'left', field: 'folio', headerClasses: 'w9p'},
        {label: 'cliente', align: 'left', field: 'cliente', headerClasses: 'w37p'},
        {label: 'status', align: 'left', name: 'statusPago', headerClasses: 'w9p'},
        {
          label: 'total',
          align: 'right',
          field: 'importeVenta',
          headerClasses: 'w10p',
          format: val => this.accounting.formatMoney(val)
        },{
          label: 'pagado',
          align: 'right',
          field: 'pagado',
          headerClasses: 'w10p',
          format: val => this.accounting.formatMoney(val)
        },
        {
          label: 'saldo',
          align: 'right',
          field: 'saldo',
          headerClasses: 'w10p',
          format: val => this.accounting.formatMoney(val)
        },

        {
          label: 'fecha registro',
          align: 'left',
          field: 'fechaRegistroSinFormato',
          headerClasses: 'w15p',
          format: val => this.dateFormat(val, 2)
        }
      ],
      ventaSeleccionada: [],
      ventasModal: false,
      cajaId: JSON.parse(localStorage.getItem("cajaObj")).caja_id,
    }
  },
  watch: {
    model(value) {
      if (value) {
        this.limpiarFiltros();
        this.buscarVentas();
      } else
        this.onClose();
    }
  },
  methods: {
    onClose() {
      this.ventasModal = false;
      this.$emit('on-close');
    },
    onShow() {
      this.loader(true);
      this.limpiarFiltros();
      this.loader(false);
    },
    limpiarFiltros() {
      this.filtros = {
        busqueda: "",
        tipo: 100,
        orden: 6,
        cajaId: this.cajaId
      };
    },
    buscarVentas(loader = true) {
      if (loader) this.loader(true);

      let filtros = {
        busqueda: this.filtros.busqueda,
        tipo: this.filtros.tipo,
        orden: this.filtros.orden,
        cajaId: this.caja.ver_todas_ventas ? "" : this.filtros.cajaId
      };

      return cajaServices.buscarVentas(filtros)
        .then(ventas => {
          if (ventas.length === 1)
            this.obtenerVenta(ventas[0]);
          else {
            this.ventas = ventas;
            this.ventasModal = true;
          }
        }).catch(error => {
          this.alertShow(error, "error");
          this.onClose();
        })
        .then(() => {if (loader) this.loader(false)});
    },
    seleccionarVentaRow(evt, row, index) {
      this.obtenerVenta(row);
    },
    seleccionarVenta(details) {
      this.obtenerVenta(details.rows[0]);
    },
    obtenerVenta(row) {
      this.ventasModal = false;
      this.$emit("get-venta", row.ventaId);
    }
  }
}
